import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Button from 'reactstrap/lib/Button';
import { Collapse } from '@kunukn/react-collapse'; // eslint-disable-line no-restricted-imports
import { useToggle } from 'site-modules/shared/hooks/use-toggle';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  ConsumerReviewsEntities,
  ConsumerReviewsModel,
  ConsumerReviewsPaths,
} from 'client/data/models/consumer-reviews';
import { VehicleEntities } from 'client/data/models/vehicle-v2';
import { getPlural } from 'client/utils/plural';
import {
  getConsumerReviewsUrl,
  getCoreUrl,
  getReviewUrl,
  getWriteReviewUrl,
} from 'site-modules/shared/utils/core-link-constructor';
import { getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import { appendTrademarkCharacter } from 'site-modules/shared/utils/inventory-utils/append-trademark-character';
import { isUsed } from 'site-modules/shared/utils/publication-states';
import { Link } from 'site-modules/shared/components/link/link';
import { ShoppingLink } from 'site-modules/shared/components/shopping-link/shopping-link';
import { InvokeAction } from 'site-modules/shared/components/inventory/invoke-action/invoke-action';
import { RatingStars } from 'site-modules/shared/components/rating-stars/rating-stars';
import { Reviews } from 'site-modules/shared/components/reviews/reviews';
import { ReviewSummaryLinks } from 'site-modules/shared/components/review-summary-links/review-summary-links';
import { ConsumerReviewAspectFilterButtons } from 'site-modules/shared/components/consumer-review-aspect-filter-buttons/consumer-review-aspect-filter-buttons';
import { TopRatedBadge } from 'site-modules/shared/components/top-rated-badge/top-rated-badge';

import './consumer-reviews-modular.scss';

export function ConsumerReviewsModularUI({
  className,
  setModelValue,
  vehicle,
  consumerReviews,
  vehicleFilter,
  ratingsCount,
  prevYearCount,
  consumerReviewsPrevYear,
  creativeId,
  isMobile,
  showResearchLink,
  isBasicViewEnabled,
  actionButtonsEnabled,
  openLinksInNewTab,
}) {
  const [aspect, setAspect] = useState(null);
  const [isOpen, toggleOpen] = useToggle(false);

  const onSetAspect = useCallback(
    newAspect => {
      setAspect(newAspect);
      setModelValue(ConsumerReviewsPaths.getConsumerReviewsFilterPath(), ConsumerReviewsModel, {
        aspect: newAspect,
      });
    },
    [setModelValue]
  );

  function clearFilter() {
    onSetAspect(null);
  }

  if (!consumerReviews || !(prevYearCount || consumerReviewsPrevYear || ratingsCount)) {
    return null;
  }

  const linkTarget = openLinksInNewTab ? '_blank' : '_self';
  const {
    make: { name: makeName, slug: makeSlug },
    model: { name: modelName, slug: modelSlug },
    year,
    pubStates,
  } = vehicle;

  const hasNewReviews = !!get(ratingsCount, 'totalReviews');
  const hasUsedReviews =
    !hasNewReviews && !!(get(prevYearCount, 'totalReviews') || get(consumerReviewsPrevYear, 'totalNumber'));
  let averageUserRating;

  if (hasUsedReviews) {
    averageUserRating =
      get(prevYearCount, 'ratingAggregation.averageStars') || get(consumerReviewsPrevYear, 'averageUserRating');
  }

  const reviews = (
    <Fragment>
      <div className="mb-0_5">
        <Reviews
          mmsy={{
            make: vehicle.make.name,
            model: vehicle.model.name,
            submodel: vehicle.submodels.name,
            year: vehicle.year,
          }}
          reviews={consumerReviews.reviews}
          isMobile={isMobile}
          classes="mt-1_5"
          reviewHeading="h4"
          isCore5497Enabled
        />
      </div>
      {get(vehicleFilter, 'years.length', 1) > 1 && (
        <div className="mt-1 mb-1 small">
          We have a limited number of reviews for the {year} {makeName} {modelName}, so we&apos;ve included reviews for
          other years of the {modelName} since its last redesign.
        </div>
      )}

      <Row>
        {!showResearchLink && (
          <Col xs={12} md={7}>
            <ShoppingLink
              to={getConsumerReviewsUrl({ makeSlug, modelSlug, year })}
              label={`See all ${ratingsCount.totalReviews} ${getPlural(
                'review',
                ratingsCount.totalReviews
              )} of the ${makeName} ${modelName}`}
              className="rounded-12 cool-gray-20-shadow px-1 py-1 mt-1"
              labelClassName="text-gray-darkest font-weight-medium"
              iconClassName="icon-star-full"
              iconContainerClassName="mr-0_75 size-16 text-white bg-blue-50"
              iconStyles={{ height: '32px', width: '32px' }}
              arrowClassName="icon-arrow-right4 text-primary-darker"
              data-tracking-id="read_all_reviews"
              data-tracking-value="Read All Reviews"
            />
            <ShoppingLink
              to={getWriteReviewUrl({ makeSlug, modelSlug, year })}
              label="Write a vehicle review"
              className="rounded-12 cool-gray-20-shadow px-1 py-1 mt-1"
              labelClassName="text-gray-darkest font-weight-medium"
              iconClassName="icon-pencil"
              iconContainerClassName="mr-0_75 size-16 text-white bg-blue-50"
              iconStyles={{ height: '32px', width: '32px' }}
              arrowClassName="icon-arrow-right4 text-primary-darker"
              data-tracking-id="write_review"
              data-tracking-value={`Write a ${year} ${makeName} ${modelName} review`}
            />
          </Col>
        )}
        {showResearchLink && (
          <Col xs={12} md={7}>
            <InvokeAction
              icon="icon-search"
              iconClassName="size-16 text-white"
              iconColor="text-gray-darker"
              iconWrapperClassName="circle-icon-wrapper bg-blue-50 rounded-circle d-inline-flex align-items-center justify-content-center col-auto"
              withIconWrapper
              primaryText={`Research the ${appendTrademarkCharacter({
                make: makeName,
                str: `${year} ${makeName} ${modelName}`,
              })}`}
              textWrapperClassName="col px-0"
              primaryColor=""
              primaryTextClassName="text-cool-gray-10 size-16 font-weight-medium pl-0_75"
              arrowClassName="display-lg text-primary-darker"
              dataTrackingId="reviews_specs"
              badge={
                <TopRatedBadge
                  vehicleParams={{ makeSlug, modelSlug, year }}
                  className="invoke-action-top-rated pl-0_75"
                />
              }
              href={
                isUsed(pubStates)
                  ? getReviewUrl({ makeSlug, modelSlug, year })
                  : getCoreUrl({ makeSlug, modelSlug, year })
              }
              classes="d-flex justify-content-between align-items-center mt-1 rounded-12 font-weight-normal px-1 py-1 border-0 research-link m-0"
              newTab
            />
          </Col>
        )}
      </Row>
    </Fragment>
  );

  return (
    <div
      className={classnames('consumer-reviews-modular rounded-12 bg-cool-gray-90 px-1 pt-1_5 pb-1', className)}
      data-tracking-parent={creativeId}
    >
      <h2 className="heading-3 mb-1_5">
        {makeName} {modelName} Reviews
      </h2>
      {hasNewReviews && (
        <Fragment>
          <div className="bg-white rounded-12 px-1 px-md-1_5 pt-1_5 pb-1 mb-1">
            <h3 className="heading-4 mb-1_5">Owner Reviews</h3>
            <div className="d-flex mb-1">
              <Link
                to={getConsumerReviewsUrl({ makeSlug, modelSlug, year })}
                className="rating-link rounded-8"
                target={linkTarget}
              >
                <div className="mb-0_25">
                  <span className="heading-3 mr-0_5">
                    {ratingsCount.ratingAggregation.averageStars.toFixed(1)}
                    <span className="sr-only">&nbsp;out of 5 stars</span>
                  </span>
                  <RatingStars
                    className="d-inline-block size-24 text-blue-50"
                    rating={ratingsCount.ratingAggregation.averageStars}
                    hideForScreenReader
                  />
                </div>
                <div className="reviews-count medium text-cool-gray-40">{ratingsCount.totalReviews} reviews</div>
              </Link>
            </div>
            <Row className="mb-0_5">
              <Col xs={12} md={7}>
                {ratingsCount.percents.map((percent, index) => {
                  const key = `${index}-${percent}`;
                  return (
                    <div
                      key={key}
                      className="percent-row heading-6 d-flex align-items-center text-cool-gray-30 mb-0_25"
                    >
                      {ratingsCount.percents.length - index}
                      <Col
                        tag="span"
                        className="percent-progress bg-cool-gray-80 d-flex align-items-stretch rounded-8 p-0 ml-0_75"
                      >
                        <span className="rounded-8 bg-blue-50" style={{ width: `${percent}%` }} />
                      </Col>
                      <span className="percent-value font-weight-normal text-right">({percent}%)</span>
                    </div>
                  );
                })}
              </Col>
            </Row>
            <ReviewSummaryLinks
              vehicle={vehicle}
              reviewsCount={ratingsCount.totalReviews}
              showAllReviewsLink
              target={linkTarget}
            />
          </div>
          <div className="bg-white rounded-12 px-1 px-md-1_5 py-1_5">
            {!isBasicViewEnabled && aspect && (
              <div className="mb-1_5">
                <h3 className="heading-4 d-inline mb-0 mr-0_5">
                  Reviews that mention &ldquo;{aspect.displayName}&rdquo;
                </h3>{' '}
                <Button
                  color="link"
                  size="lg"
                  className="clear-filter d-inline-block font-weight-normal p-0 text-capitalize text-underline"
                  onClick={clearFilter}
                >
                  Clear filter
                </Button>
              </div>
            )}
            {!isBasicViewEnabled && !aspect && <h3 className="heading-4 mb-1_5">Most Helpful Owner Reviews</h3>}

            {!isBasicViewEnabled && (
              <ConsumerReviewAspectFilterButtons
                vehicle={vehicle}
                isMobile={isMobile}
                setAspect={onSetAspect}
                active={aspect}
                heading="h4"
                headerText="Trending topics"
                headerClassName="size-16 font-weight-medium mb-1"
              />
            )}

            {isBasicViewEnabled && (
              <Button
                color="link"
                className="heading-4 mb-1 mt-0 text-transform-none text-decoration-none p-0"
                onClick={toggleOpen}
                data-tracking-id="view_more_info"
                data-tracking-value={isOpen ? 'collapse' : 'expand'}
              >
                Most Helpful Owner Reviews
                <i
                  className={classnames('text-primary-darker small ml-0_25', {
                    'icon-arrow-down4': !isOpen,
                    'icon-arrow-up4': isOpen,
                  })}
                  aria-hidden
                />
              </Button>
            )}
            {isBasicViewEnabled ? <Collapse isOpen={isOpen}>{reviews}</Collapse> : reviews}
          </div>
        </Fragment>
      )}
      {!hasNewReviews && (
        <div className="bg-white rounded-12 px-1 px-md-1_5 pt-1_5 pb-1_5">
          {hasUsedReviews && (
            <Fragment>
              <h3 className="heading-4 mb-1">Owner Reviews</h3>
              <div className="mb-1">
                <span className="heading-3 mr-0_5">
                  {averageUserRating.toFixed(1)}
                  <span className="sr-only">&nbsp;out of 5 stars</span>
                </span>
                <RatingStars
                  className="d-inline-block size-24 text-blue-50"
                  rating={averageUserRating}
                  hideForScreenReader
                />
              </div>
            </Fragment>
          )}
          <h3 className="size-16 font-weight-normal mb-1_5">
            There are no consumer reviews for the {year} {makeName} {modelName}.{' '}
            {hasUsedReviews && (
              <Fragment>
                The rating displayed is for previous years of the {makeName} {modelName}.
              </Fragment>
            )}
          </h3>
          <div>
            <Link
              to={getWriteReviewUrl({ makeSlug, modelSlug, year })}
              className="text-primary-darker"
              data-tracking-id="write_review"
              data-tracking-value={`Write a ${year} ${makeName} ${modelName} review`}
              target={linkTarget}
            >
              Be the first to write a review <i className="icon-arrow-right4 size-10" aria-hidden />
            </Link>
          </div>
          {hasUsedReviews && (
            <div className="mt-0_5">
              <Link
                to={getConsumerReviewsUrl({ makeSlug, modelSlug, year: year - 1 })}
                className="text-primary-darker"
                data-tracking-id="read_all_reviews"
                data-tracking-value="Read All Reviews"
                rel="nofollow"
                target={linkTarget}
              >
                Read {year - 1} {makeName} {modelName} reviews <i className="icon-arrow-right4 size-10" aria-hidden />
              </Link>
            </div>
          )}
          {actionButtonsEnabled && (
            <div className="mt-0_5">
              <Link
                to={getConsumerReviewsUrl({ makeSlug, modelSlug, year })}
                className="text-primary-darker"
                data-tracking-id="read_all_reviews"
                data-tracking-value="Read All Reviews"
                rel="nofollow"
                target="_blank"
              >
                Read all reviews for the{' '}
                {appendTrademarkCharacter({
                  make: makeName,
                  str: `${makeName} ${modelName}`,
                })}
                <i className="icon-arrow-right4 size-10" aria-hidden />
              </Link>
            </div>
          )}
          {showResearchLink && (
            <div className="mt-0_5">
              <Link
                to={
                  isUsed(pubStates)
                    ? getReviewUrl({ makeSlug, modelSlug, year })
                    : getCoreUrl({ makeSlug, modelSlug, year })
                }
                className="text-primary-darker"
                data-tracking-id="read_all_reviews"
                data-tracking-value="Read All Reviews"
                rel="nofollow"
                target="_blank"
              >
                Research the{' '}
                {appendTrademarkCharacter({
                  make: makeName,
                  str: `${year} ${makeName} ${modelName}`,
                })}{' '}
                <i className="icon-arrow-right4 size-10" aria-hidden />
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

ConsumerReviewsModularUI.propTypes = {
  setModelValue: PropTypes.func.isRequired,
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  className: PropTypes.string,
  consumerReviews: ConsumerReviewsEntities.ConsumerReviewsContent,
  vehicleFilter: ConsumerReviewsEntities.VehicleFilter,
  ratingsCount: ConsumerReviewsEntities.ConsumerReviewsRatingsCount,
  prevYearCount: ConsumerReviewsEntities.ConsumerReviewsRatingsCount,
  consumerReviewsPrevYear: ConsumerReviewsEntities.ReviewContent,
  creativeId: PropTypes.string,
  isMobile: PropTypes.bool,
  showResearchLink: PropTypes.bool,
  isBasicViewEnabled: PropTypes.bool,
  actionButtonsEnabled: PropTypes.bool,
  openLinksInNewTab: PropTypes.bool,
};

ConsumerReviewsModularUI.defaultProps = {
  className: null,
  consumerReviews: null,
  vehicleFilter: null,
  ratingsCount: null,
  prevYearCount: null,
  consumerReviewsPrevYear: null,
  creativeId: null,
  isMobile: false,
  showResearchLink: false,
  isBasicViewEnabled: false,
  actionButtonsEnabled: false,
  openLinksInNewTab: false,
};

export const propsAreEqual = (prevProps, { consumerReviews }) => !consumerReviews;
export const ConsumerReviewsModular = connectToModel(React.memo(ConsumerReviewsModularUI, propsAreEqual), {
  vehicleFilter: bindToPath(ConsumerReviewsPaths.getVehicleFilterPath(), ConsumerReviewsModel),
  consumerReviews: bindToPath(ConsumerReviewsPaths.getConsumerReviewsPath(), ConsumerReviewsModel),
  ratingsCount: bindToPath(
    ({ vehicle }) => ConsumerReviewsPaths.buildConsumerReviewsRatingsCountPath(getParamsFromVehicle(vehicle)),
    ConsumerReviewsModel
  ),
  prevYearCount: bindToPath(
    ({ vehicle }) =>
      ConsumerReviewsPaths.buildConsumerReviewsRatingsCountPath({
        ...getParamsFromVehicle(vehicle),
        year: vehicle.year - 1,
      }),
    ConsumerReviewsModel
  ),
});
